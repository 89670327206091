
export default {
  name: "Sindex",
  layout: "main-head",
  data() {
    return {
      tabList: [],
      tabId: "",
      list:[],
      params:{
        pageNum:1,
        pageSize:120
      },
      isLast:false
    };
  },
  head() {
    return this.setHead();
  },
  async asyncData({ $api, cookies, ...context }) {
        const [response, categoryR] = await Promise.all([
            $api.home.initialLetters(),
            $api.home.firstLetterQuery({
              pageNum:context?.query?.page||1,
              pageSize:120,
              letter:''
            })
        ])
        const res = response?.result || [];
        const resR = categoryR?.result?.list || [];
        let list = [{tabName:'All',tabValue:''}]
        if(res?.length!=0){
          res?.map((item) => {
            list.push({
              tabName: item,
              tabValue: item,
            });
          });
        }else{
          list = []
        }
        return {
            tabList: list,
            list:resR,
            params:{
              pageNum:context?.query?.page||1,
              pageSize:120,
              totalPage:categoryR?.result?.totalPage||0,
              total:categoryR?.result?.total||0
            }
        }
    },
  mounted() {
    // this.getCategoryFirstLetter();
    // this.getfirstLetterQuery()
  },
  methods: {
    handleTab(){
      this.params.pageNum = 1
      this.list = []
      this.isLast = false
      history.replaceState(null, '', window.location.pathname);
      this.getfirstLetterQuery()
    },
    getList(e){
      this.params.pageNum = e
      this.getfirstLetterQuery()
      this.$VueScrollTo.scrollTo('body')
      let pages = e;
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('page', pages);
      let newUrl = '';
      newUrl = window.location.pathname + '?' + queryParams.toString();
      if(pages>1){
        history.replaceState(null, '', newUrl);
      }else{
        history.replaceState(null, '', window.location.pathname);
      }
    },
    getfirstLetterQuery(){
      this.$api.home.firstLetterQuery({...this.params,letter:this.tabId}).then((res) => {
        this.list = res.result.list
        this.params.totalPage = res?.result.totalPage
        this.params.total = res?.result.total
      });
    },
    getCategoryFirstLetter() {
      this.tabList = [{tabName:'All',tabValue:''}];
      this.$api.home.initialLetters().then((res) => {
        res?.result?.map((item) => {
          this.tabList.push({
            tabName: item,
            tabValue: item,
          });
        });
      });
    },
    // SEO HEAD
    setHead() {
      const head = this.$ctxApi.seoHead.initial({
        title: this.$translate("Product Index"),
        keywords: this.$translate("Product Index"),
        description: this.$translate(
          "Explore the YFN index by Alphabetical Tag to easily shop all products."
        ),
        image: "https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png",
      });
      return {
        ...head,
      };
    },
  },
};
